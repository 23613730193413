const PersonalPage = () => {
  return (
    <div>
      <h1>Kamal Selim</h1>
      <p>Main Page</p>
    </div>
  );
};

export default PersonalPage;
